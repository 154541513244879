.icon-arrow {
	width: 32px;
	height: 32px;
}

.icon-check {
	width: 24px;
	height: 16.9px;
}

.icon-close {
	width: 17px;
	height: 17px;
}

.icon-email {
	width: 16px;
	height: 12px;
}

.icon-facebook {
	width: 768px;
	height: 763.3px;
}

.icon-map {
	width: 40.8px;
	height: 52.8px;
}

.icon-menu {
	width: 22px;
	height: 12px;
}

.icon-mobile {
	width: 75.1px;
	height: 139.4px;
}

.icon-play {
	width: 34px;
	height: 34px;
}

.icon-replay {
	width: 32px;
	height: 40px;
}

.icon-youtube {
	width: 32px;
	height: 32px;
}

